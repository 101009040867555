import React from 'react';

const ContactsPage: React.FC = () => {
    return (
        <div className="contacts-page">
            <h2>Contacts</h2>
        </div>
    );
};

export default ContactsPage;
